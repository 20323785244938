<template>
  <div>
    <vx-card ref="filterCard" class="user-list-filters mb-8 mt-6">
      <div class="vx-row">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Chatbot</label>
          <v-select
            v-model="chatOption"
            :options="chatOptions"
            :clearable="false"
            class="mb-4 md:mb-0"
          />
        </div>
        <div id="dateFilter" class="vx-col md:w-1/4 sm:w-2/3 w-full">
          <label class="text-sm opacity-75">Data</label>
          <vs-select
            icon="icon-calendar"
            icon-pack="feather"
            class="no-icon-border cursor-pointer w-full"
            placeholder="Filtre por data"
            size="small"
            v-model="filterParam"
          >
            <vs-select-item
              v-for="(item, index) in timeOptions"
              :key="index"
              :value="item"
              :text="item"
              class="w-full"
            />
            <div @click="popupDatePicker = true">
              <vs-select-item
                :value="timeOptionCustomValue"
                :text="timeOptionCustomText"
                class="w-full"
              />
            </div>
          </vs-select>
        </div>
        <!-- date-picker -->
        <vs-popup
          class="holamundo"
          :title="timeOptionCustomText"
          :active.sync="popupDatePicker"
        >
          <!-- popup body -->
          <date-picker
            ref="customDatePicker"
            :key="customDatePickerKey"
            v-model="datePickerValue"
            :default-value="datePickerValueDefault"
            :disabled-date="disabledDate"
            format="DD-MM-YYYY"
            range
            inline
            confirm
            @change="handleConfirmDatePicker"
          />
        </vs-popup>
        <div class="right-0 absolute hidden md:block">
          <vs-tooltip :text="getDownloadButtonTooltip">
            <vs-button
              id="btnDownloadXls"
              icon-pack="feather"
              icon="icon-file"
              size="medium"
              class="ml-4 mr-6 mt-6 vs-con-loading__container"
              @click="requestDownloadReports()"
              :disabled="hasDownloadBlocked || downloadIsRestricted"
            >
              {{ $t("DownloadXLSX") }}
            </vs-button>
          </vs-tooltip>
        </div>
      </div>
      <div class="vx-row">
        <div id="chatbotFilter" class="vx-col mt-3 w-full">
          <p class="text-sm opacity-75">{{ $t("InfoDash") }}</p>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col mt-5">
          <vs-button type="line" class="px-1" @click="popupExportAudit = true"
            >Acessar auditoria de exportações</vs-button
          >
        </div>
      </div>
    </vx-card>

    <div class="mt-8">
      <h3 class="m-2">Atendimentos encerrados</h3>
      <div class="vx-row">
        <ChartEndedConversations
          :dateRange="getDateRange"
          :endedConversations="endedConversations.closedConversations"
          :chartData="performanceData.endedConversations"
          :chartKey="endedConversationsKey"
          :key="`endendconv-${endedConversationsKey}`"
        />
        <CardConversationEnded
          v-if="endedConversations"
          :finishReasons="endedConversations"
          :key="`endendconv2-${endedConversationsKey}`"
        />
        <CardLostBusinessReason
          :lostBusinessReasons="endedConversations"
          :key="`endendconv3-${endedConversationsKey}`"
        />
      </div>
    </div>

    <div class="mt-8">
      <TableTeamData
        :tableTitle="'Performance por atendente'"
        :teamData="teamData"
        :key="`userper-${userPerformanceKey}`"
      />
    </div>

    <div class="mt-8">
      <TableEntityData
        :tableTitle="'Performance por entidade chave'"
        :entityData="keyEntities"
        :key="`entitydata-${entityDataKey}`"
      />
    </div>

    <div class="mt-8">
      <TableTypeData
        tableTitle="Performance por tipo"
        :typeData="typeData"
        :key="`typeDataKey-${typeDataKey}`"
      />
    </div>

    <vs-popup
      title="Auditoria de Exportações"
      :active.sync="popupExportAudit"
      fullscreen
    >
      <vs-row>
        <vs-col>
          <span>
            Aqui você encontra a auditoria de exportações da sua conta Duotalk.
            Relatórios exportados nos últimos 30 dias ficarão disponíveis para
            download de forma rápida e acessível. Basta clicar no botão de
            download e copiar a senha na coluna 'Ações' para ter acesso aos
            dados baixados.
          </span>
        </vs-col>
        <vs-col class="mt-5">
          <vs-table
            stripe
            hoverFlat
            :data="exportAudit"
            :noDataText="$t('EmptyTable')"
          >
            <template slot="thead">
              <vs-th sort-key="chat">Fonte</vs-th>
              <vs-th>Filtro</vs-th>
              <vs-th sort-key="records">Registros</vs-th>
              <vs-th sort-key="user">Usuário</vs-th>
              <vs-th sort-key="createdAt">Data</vs-th>
              <vs-th sort-key="status">Status</vs-th>
              <vs-th class="w-5">Ações</vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr v-for="(tr, index) in data" :key="index">
                <vs-td
                  >{{ tr.chat === null ? "Todos os chatbots" : tr.chat }}
                </vs-td>
                <vs-td v-if="tr.filter"
                  >{{ new Date(tr.filter.start).toLocaleDateString("pt-br") }}
                  até
                  {{
                    new Date(tr.filter.end).toLocaleDateString("pt-br")
                  }}</vs-td
                >
                <vs-td v-else>Nenhum filtro aplicado</vs-td>
                <vs-td>{{ tr.records }}</vs-td>
                <vs-td>{{ tr.userEmail }}</vs-td>
                <vs-td>{{
                  new Date(tr.createdAt).toLocaleString("pt-br")
                }}</vs-td>
                <vs-td>
                  <vs-chip transparent :color="status[tr.status].color">
                    {{ status[tr.status].label }}
                  </vs-chip>
                </vs-td>
                <vs-td>
                  <div class="flex items-center justify-between gap-x-2">
                    <vs-tooltip text="Download">
                      <vs-button
                        :disabled="status[tr.status].disabled"
                        target
                        :href="tr.downloadLink"
                        type="flat"
                        icon-pack="feather"
                        icon="icon-download"
                      />
                    </vs-tooltip>
                    <vs-tooltip text="Copiar senha">
                      <vs-button
                        :disabled="status[tr.status].disabled"
                        type="flat"
                        icon-pack="feather"
                        icon="icon-copy"
                        @click="copyPassword(tr.password)"
                      />
                    </vs-tooltip>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div class="flex justify-end mt-4">
            <div class="flex items-center justify-between">
              <vs-select label="Limite por página" v-model="limit">
                <vs-select-item
                  v-for="(item, index) in limitOptions"
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                />
              </vs-select>
              <vs-pagination
                class="mt-8"
                :total="pagination ? pagination.pages : 1"
                v-model="currentPage"
                @change="fetchTasks(currentPage, limit)"
              ></vs-pagination>
            </div>
          </div>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from "vue-select";

import CardConversationEnded from "./components/CardConversationEnded.vue";
import CardLostBusinessReason from "./components/CardLostBusinessReason.vue";
import ChartEndedConversations from "./components/ChartEndedConversations.vue";

//date picker
import axios from "@/axios.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
import TableEntityData from "./components/TableEntityData.vue";
import TableTeamData from "./components/TableTeamData.vue";
import TableTypeData from "./components/TableTypeData.vue";

export default {
  mounted() {
    this.fetchTasks(this.currentPage, this.limit);
    this.verifyDownloadReports();
    this.getChats();
    this.getEndedConversations(this.getDateByPeriod, "all");
    this.restrictDownload();
  },
  components: {
    vSelect,
    CardLostBusinessReason,
    CardConversationEnded,
    ChartEndedConversations,
    DatePicker,
    TableEntityData,
    TableTeamData,
    TableTypeData,
  },
  watch: {
    filterParam: function () {
      if (this.filterParam === this.timeOptionCustomText) {
        // window.alert('custom data selected')
        this.popupDatePicker = true;
        return;
      }
      this.applyFilters(this.filterParam);
    },
    limit: function () {
      this.currentPage = 1;
      this.fetchTasks(this.currentPage, this.limit);
    },
    chatOption: function () {
      this.applyFilters(this.filterParam);
    },
  },
  data() {
    return {
      status: {
        FAILED: {
          label: "Não enviado",
          color: "danger",
          disabled: true,
        },
        COMPLETED: {
          label: "Concluído",
          color: "success",
          disabled: false,
        },
        PENDING: {
          label: "Em andamento",
          color: "warning",
          disabled: true,
        },
        PROCESSING: {
          label: "Em andamento",
          color: "warning",
          disabled: true,
        },
      },
      exportAudit: [],
      currentPage: 1,
      pagination: {},
      popupExportAudit: false,
      customDatePickerKey: 0,
      popupDatePicker: false,
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "15", value: 15 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      limit: 10,
      datePickerValueDefault: [
        new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000), // 90 days ago
        new Date(), // today's date
      ],
      datePickerValue: null,
      downloadIsRestricted: false,
      downloadRestrictedMessage: this.$t("DownloadRestrictedMessage"),
      chatOptions: [{ label: this.$t("AllChatbots"), value: "all" }],
      chatOption: [],
      teamMembers: [],
      teamMembersKey: 0,
      keyEntities: null,
      entityData: [],
      entityDataKey: 0,
      typeData: [],
      typeDataKey: 0,
      performanceKey: 0,
      performanceData: {},
      newConversations: Number,
      endedConversations: [],
      surveyData: {},
      userPerformanceData: [],
      teamData: [],
      filterDateNames: this.$t("FilterDates"),
      userPerformanceKey: 0,
      endedConversationsKey: 0,
      filterParam: "Este mês",
      timeOptions: [
        "Este mês",
        "Mês passado",
        "Esta semana",
        "Semana passada",
        "Este ano",
        "Ano passado",
      ],
      timeOptionCustomValue: "Escolher Data",
      timeOptionCustomText: "Escolher Data",
      dataFilter: "thisMonth",
      downloadBlockedMessage: this.$t(
        "InboxConversationsXlsxGenerationBlocked1"
      ),
      hasDownloadBlockedLocal: false,
      hasDownloadBlocked: true,
    };
  },
  computed: {
    getDateByPeriod() {
      const today = new Date();
      today.setHours(20, 59, 59, 59);
      let rangeDate;
      switch (this.filterParam) {
        case "Este mês":
          rangeDate = {
            start: new Date(today.getFullYear(), today.getMonth(), 1),
            end: today,
          };
          break;
        case "Esta semana": {
          const firstDayOfWeek = today.getDate() - today.getDay();
          rangeDate = {
            start: new Date(
              today.getFullYear(),
              today.getMonth(),
              firstDayOfWeek
            ),
            end: today,
          };
          break;
        }
        case "Este ano":
          rangeDate = {
            start: new Date(today.getFullYear(), 0, 1),
            end: today,
          };
          break;
        case "Semana passada": {
          const lastWeekStart = new Date(today);
          lastWeekStart.setDate(today.getDate() - today.getDay() - 7);
          const lastWeekEnd = new Date(lastWeekStart);
          lastWeekEnd.setDate(lastWeekStart.getDate() + 6);
          rangeDate = {
            start: lastWeekStart,
            end: lastWeekEnd,
          };
          break;
        }
        case "Mês passado":
          rangeDate = {
            start: new Date(today.getFullYear(), today.getMonth() - 1, 1),
            end: new Date(today.getFullYear(), today.getMonth(), 0),
          };
          break;
        case "Ano passado":
          rangeDate = {
            start: new Date(today.getFullYear() - 1, 0, 1),
            end: new Date(today.getFullYear() - 1, 11, 31),
          };
          break;
        case "Escolher Data":
          rangeDate = {
            start: new Date(this.datePickerValue[0]),
            end: new Date(this.datePickerValue[1]),
          };
          if (
            this.datePickerValue[0].toString() ===
            this.datePickerValue[1].toString()
          ) {
            rangeDate.start.setUTCHours(0, 0, 0, 0);
            rangeDate.end.setUTCHours(23, 59, 59, 59);
          } else {
            rangeDate.start.setUTCHours(
              rangeDate.start.getHours(),
              rangeDate.start.getMinutes(),
              rangeDate.start.getSeconds()
            );

            rangeDate.end.setUTCHours(23, 59, 59, 59);
          }
          break;
        default:
          rangeDate = {
            start: new Date(today.getFullYear(), today.getMonth(), 1),
            end: today,
          };
      }
      return rangeDate;
    },
    getDateRange() {
      let dateMatch;
      if (this.dataFilter === "thisMonth") {
        const thisMonth = new Date();
        thisMonth.setHours(0, 0, 0, 0);

        const firstDay = new Date(
          thisMonth.getFullYear(),
          thisMonth.getMonth(),
          1
        );

        const lastDay = new Date(thisMonth);

        lastDay.setDate(lastDay.getDate() - 1);

        dateMatch = { start: firstDay, end: lastDay };
      }

      if (this.dataFilter === "lastMonth") {
        const lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        lastMonth.setHours(0, 0, 0, 0);

        const firstDay = new Date(
          lastMonth.getFullYear(),
          lastMonth.getMonth(),
          1
        );
        const lastDay = new Date(
          lastMonth.getFullYear(),
          lastMonth.getMonth() + 1,
          0
        );

        dateMatch = { start: firstDay, end: lastDay };
      }

      if (this.dataFilter === "thisWeek") {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const first = today.getDate() - today.getDay();
        const last = first + 6;

        const startThisWeek = new Date(today.setDate(first));
        startThisWeek.setHours(0, 0, 0, 0);
        const endThisWeek = new Date(today.setDate(last));
        endThisWeek.setHours(0, 0, 0, 0);

        endThisWeek.setDate(endThisWeek.getDate() - 1);

        dateMatch = { start: startThisWeek, end: endThisWeek };
      }

      if (this.dataFilter === "lastWeek") {
        const dayOfLastWeek = new Date();
        dayOfLastWeek.setHours(0, 0, 0, 0);
        dayOfLastWeek.setDate(dayOfLastWeek.getDate() - 7);

        const first = dayOfLastWeek.getDate() - dayOfLastWeek.getDay();
        const last = first + 6;

        const startLastWeek = new Date(dayOfLastWeek.setDate(first));
        startLastWeek.setHours(0, 0, 0, 0);
        const endLastWeek = new Date(dayOfLastWeek.setDate(last));
        endLastWeek.setHours(0, 0, 0, 0);

        dateMatch = { start: startLastWeek, end: endLastWeek };
      }

      if (this.dataFilter === "thisYear") {
        const currentYear = new Date().getFullYear();

        const firstDayOfYear = new Date(currentYear, 0, 1);
        firstDayOfYear.setHours(0, 0, 0, 0);

        const lastDayOfYear = new Date(currentYear, 11, 31);
        lastDayOfYear.setHours(0, 0, 0, 0);

        dateMatch = { start: firstDayOfYear, end: lastDayOfYear };
      }

      if (this.dataFilter === "lastYear") {
        const lastYear = new Date();
        lastYear.setFullYear(lastYear.getFullYear() - 1);

        const firstDayOfYear = new Date(lastYear.getFullYear(), 0, 1);
        firstDayOfYear.setHours(0, 0, 0, 0);

        const lastDayOfYear = new Date(lastYear.getFullYear(), 11, 31);
        lastDayOfYear.setHours(0, 0, 0, 0);
        dateMatch = { start: firstDayOfYear, end: lastDayOfYear };
      }
      return dateMatch;
    },
    disabledDate() {
      const today = new Date();
      // return (date) => date < minDate || date > today;
      today.setHours(0, 0, 0, 0);

      return (date) => date > today;
    },
    getDownloadButtonTooltip() {
      switch (true) {
        case this.downloadIsRestricted:
          return this.downloadRestrictedMessage;
        case this.hasDownloadBlocked:
          return this.downloadBlockedMessage;
        default:
          return this.$t("DownloadCSV");
      }
    },
  },
  methods: {
    /*************  ✨ Codeium Command ⭐  *************/
    /**
     * @function handleConfirmDatePicker
     * @description After user selects custom date filter, this function is called.
     * It will hide the date picker popup, increase the customDatePickerKey (to force rerender component)
     * and update the timeOptionCustomText with the selected dates.
     * After that, it will call applyFilters with the new timeOptionCustomText.
     * @param {void} No params needed.
     * @returns {void} No return.
     */
    /******  b220393c-bdaf-4b95-b17d-a4a1632526b1  *******/
    handleConfirmDatePicker() {
      this.popupDatePicker = false;
      this.customDatePickerKey += 1;
      this.$nextTick(() => {
        const customDatePickerText = this.$refs.customDatePicker.text;
        this.timeOptionCustomText = !customDatePickerText
          ? this.timeOptionCustomText
          : customDatePickerText;
      });
      this.applyFilters(this.timeOptionCustomText);
    },
    async applyFilters(param) {
      if (param !== this.timeOptionCustomText) {
        this.datePickerValue = null;
        this.timeOptionCustomText = this.timeOptionCustomValue;
      }

      await this.getEndedConversations(
        this.getDateByPeriod,
        this.chatOption.value
      );
    },
    async restrictDownload() {
      if (
        this.$store.state.acc.restrictReportDownload &&
        this.$store.state.user.userRole === "manager"
      )
        this.downloadIsRestricted = true;
      else {
        this.downloadIsRestricted = false;
      }
    },
    async getChats() {
      await this.$http
        .get("/p/chat/list", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
          },
        })
        .then((res) => {
          if (this.chatOptions.length === 1) {
            this.activeChats = false;
            this.chatOption = this.chatOptions[0];
            for (let i = 0; i < res.data.data.length; i++) {
              this.chatOptions.push({
                label: res.data.data[i].name,
                value: res.data.data[i]._id,
              });
              if (res.data.data[i].status === 1) this.activeChats = true;
              if (res.data.data[i].status === 3) this.pendingChats = true;
            }
          }
        })
        .catch(() => {});
    },
    async getEndedConversations(dateFilter, chatbot) {
      const response = await this.$http.get(
        "/p/chat/inbox/new-dashboard/performance-2",
        {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            filter: dateFilter,
            chatbot,
          },
        }
      );

      if (response.status === 200) {
        this.endedConversations = response.data.data;

        this.keyEntities = response.data.data.keyEntities;
        this.teamData = response.data.data.performanceData;
        this.typeData = response.data.data.originData;

        this.endedConversationsKey += 1;
        this.userPerformanceKey += 1;
        this.entityDataKey += 1;
        this.typeDataKey += 1;

        this.surveyData = {
          csat: response.data.data.csat,
          nps: response.data.data.nps,
        };
      }
    },
    fetchTasks(page, limit) {
      axios
        .get(
          "/p/chat/inbox/conversationsCsv/tasks?acc=" +
            this.$store.state.acc.current_acc.id +
            `&page=${page}` +
            `&limit=${limit}`
        )
        .then((response) => {
          this.exportAudit = response.data.data.tasks;
          this.pagination = response.data.data.pagination;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    verifyDownloadReports() {
      this.$http
        .get(
          "/p/chat/inbox/conversationsCsv/hasTasksProcessing?acc=" +
            this.$store.state.acc.current_acc.id
        )
        .then((response) => {
          this.hasDownloadBlocked = response.data.data.hasTasksProcessing;
          if (this.hasDownloadBlocked) {
            setTimeout(() => {
              this.verifyDownloadReports();
            }, 5000);
          }
        })
        .catch(() => {
          //console.log(error);
        });
    },
    requestDownloadReports() {
      this.$vs.loading({
        container: "#btnDownloadXls",
        scale: 0.45,
      });
      this.$http
        .get(
          "/p/chat/inbox/conversationsCsv?acc=" +
            this.$store.state.acc.current_acc.id +
            "&filter=" +
            JSON.stringify(this.getDateByPeriod) +
            "&chat=" +
            this.chatOption.value
        )
        .then(() => {
          this.$vs.notify({
            title: "Exportação em andamento",
            text: "O arquivo XLSX será enviado por e-mail assim que estiver pronto. Aguarde a conclusão antes de iniciar um novo download.",
            iconPack: "feather",
            icon: "icon-check",
            color: "success",
            position: "top-right",
          });
          this.verifyDownloadReports();
        })
        .catch(() => {
          //console.log(error);

          this.$vs.notify({
            title: this.$t("Error"),
            text: "Ocorreu um erro ao tentar exportar o arquivo XLSX. Tente novamente mais tarde.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 4000,
          });
        })
        .finally(() => {
          this.$vs.loading.close("#btnDownloadXls > .con-vs-loading");
        });
    },
    copyPassword(password) {
      navigator.clipboard
        .writeText(password)
        .then(() => {
          this.$vs.notify({
            title: "Sucesso",
            text: "Senha copiada com sucesso!",
            iconPack: "feather",
            icon: "icon-check",
            color: "success",
            position: "top-right",
          });
        })
        .catch(() => {
          this.$vs.notify({
            title: "Erro",
            text: "Erro ao copiar senha.",
            iconPack: "feather",
            icon: "icon-x",
            color: "danger",
            position: "top-right",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#dateFilter {
  margin-left: 10px;
}

.mx-datepicker-main {
  z-index: 40000;
}

:deep(.mx-input) {
  width: 100%;
  height: 39px;
  padding: 10px;
  font-size: 1rem;
  color: inherit;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  transition: all 0.2s ease;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep(.mx-datepicker) {
  width: 100%;
  height: 39px;
}
</style>
